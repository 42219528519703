<template>
  <div class="page flex h-full flex-col items-stretch justify-start">
    <header
      class="page-header img aspect-[4/3] max-h-[500px] w-full overflow-clip"
    >
      <img
        class="primary-image h-full w-full object-cover object-center"
        src="/img/prayer-hero.jpg"
        :alt="t('hero-alt')"
      />
    </header>
    <div
      class="page-main body mx-auto flex max-w-[65ch] flex-grow flex-col items-center justify-start gap-8 py-8 px-4 lg:min-h-[calc(100vh-500px)]"
    >
      <h1 class="page-title text-center font-serif text-4xl font-bold">
        {{ t('title') }}
      </h1>
      <div>
        <NuxtLink
          class="action btn-primary btn relative flex w-max px-3 py-2 tracking-wide"
          :to="localePath('/prayer/submit')"
        >
          {{ t('Request a Prayer') }}
        </NuxtLink>
      </div>
      <i18n-t keypath="body.message" tag="p" class="text-center font-medium">
        <a
          v-if="te('body.href')"
          :href="t('body.href')"
          class="text-brand-turquoise underline transition-all duration-75 hover:no-underline dark:text-brand-sky"
          target="_blank"
        >
          {{ t('body.confidential') }}
        </a>
        <span v-else>
          {{ t('body.confidential') }}
        </span>
      </i18n-t>
    </div>
  </div>
</template>

<script type="ts" setup>
const { t, te } = useI18n()
const localePath = useLocalePath()

useHead({
  title: t('title'),
})

useSeoMeta({
  description: t('title'),
  ogTitle: t('Request Prayer'),
  ogDescription: t('title'),
  ogImage: '/img/prayer-hero.jpg',
  ogUrl: localePath('/prayer'),
  twitterTitle: t('Request Prayer'),
  twitterDescription: t('title'),
  twitterImage: '/img/prayer-hero.jpg',
  twitterCard: 'summary'
})
</script>

<style lang="postcss" scoped>
.placeholder {
  @apply m-auto flex h-full w-full flex-col place-content-center bg-slate-500 text-center text-xl font-bold opacity-50;
}
</style>

<i18n lang="json">
{
  "en": {
    "title": "It's our sacred honor to pray with you.",

    "body": {
      "message": "All Unity prayer requests are {0} and are free of charge. We are available 24/7.",
      "confidential": "kept confidential",
      "href": "https://www.unity.org/static/contact-unity-world-headquarters#calls"
    },
    "hero-alt": "A serene image of a forest of trees and lilac"
  },
  "es": {
    "title": "Es un privilegio sagrado orar contigo.",
    "body": {
      "message": "Todas las peticiones de oración a Unity son {0} y gratuitas. Estamos disponibles 24 horas al día, 7 días a la semana.",
      "confidential": "confidenciales",
      "href": "https://www.unity.org/static/politica-de-privacidad#llamarministerio"
    },
    "hero-alt": "Una imagen serena de un bosque de árboles y lilas"
  }
}
</i18n>
